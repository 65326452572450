<template lang="pug">
  .modal-confirm-back
    .modal-confirm-wrapper
      .modal-confirm-body
        .header
          slot
            .title {{ title }}
      .modal-confirm-footer
        AppButton.resolve(
          @click="$emit('resolve')"
          title="actions.ok"
        )
        AppButton.reject(
          v-if="showCancel"
          title="actions.cancel"
          @click="$emit('reject')"
        )
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      showCancel: {
        type: Boolean,
        default: true
      }
    },
    components: {
      AppButton: () => import("@/components/elements/AppButton")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .modal-confirm-back
    display: flex
    align-items: center
    justify-content: center
    background-color: rgba(43,63,87,0.5)
    height: 100%
    width: 100%
    position: fixed
    left: 0
    top: 0
    z-index: 10000002 // z-index of AppDropdown + 1

  .modal-confirm-wrapper
    background-color: $default-white
    border-radius: 0.3rem
    padding: 2rem
    width: 35rem
    max-height: 90vh

    display: flex
    flex-direction: column
    gap: 2rem

    .modal-confirm-body
      .header
        .title
          width: 100%
          text-align: center
          color: $default-black
          font-weight: 400
          font-size: 1.2rem

    .modal-confirm-footer
      display: flex
      justify-content: flex-end
      gap: 0.5rem
      border-top: none

      .resolve
        transition: all 0.1s ease-in-out
        background: $default-purple
        border: none
        color: $default-white
        font-size: 0.8rem

        &:hover
          background: darken($default-purple, 5%)

      .reject
        transition: all 0.1s ease-in-out
        border: 1px solid lighten($default-gray-medium, 20%)

        &:hover
          background: $default-gray-light
          border: 1px solid lighten($default-gray-medium, 10%)

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
      .header
        .title
          font-family: sans-serif !important
          font-weight: 500
</style>
